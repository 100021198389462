<script>
  /**
   * Kaminzimmer
   * © 2020, Hendrik Runte
   **/

  import { version } from './lib/version.js';
  import { log } from './lib/log.js';

  import Revision from './components/Revision.svelte';

  log(
    '%cThis is the Kaminzimmer client. It`s assembled with svelte.',
    'background-color: fuchsia; color: black; padding: 0 1em'
  );

  window.HELP_IMPROVE_VIDEOJS = false;
</script>

<!--
	Mind the 'global' attribute!
	This is needed to let 'prependData' work.
	See rollup.config.js
-->
<style global lang="scss">
</style>

<header />
<main>
  <video
    id="kaminzimmer-player"
    class="video-js"
    controls
    preload="auto"
    poster="/images/fireplace-sharp.jpg"
    data-setup="">
    <source
      src="https://stream.granus.net/hls/wald.m3u8"
      type="application/x-mpegURL"
      res="9999"
      label="auto" />
    <p class="vjs-no-js">
      To view this video please enable JavaScript, and consider upgrading to a
      web browser that
      <a href="https://videojs.com/html5-video-support/" target="_blank">
        supports HTML5 video
      </a>
    </p>
  </video>
</main>
<Revision {version} />
